<template>
  <v-container>
    <BaseTableList
      title="Histórico de arquivos revisados e enviados pela corretora"
    >
      <template v-slot:table>
        <v-data-table
          dense
          disable-sort
          hide-default-footer
          item-key="id"
          :headers="headers"
          :items="items"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>

          <template v-slot:[`item.situation`]="{ item }">
            <v-autocomplete
              class="b-field-select-grid"
              dense
              hide-details
              hide-selected
              single-line
              solo
              flat
              :readonly="cannotEdit"
              :items="situation"
              @change="changeSituation(item)"
              v-model="item.situation"
            />
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="success"
                  small
                  @click="downloadDocument(item)"
                >
                  fa-solid fa-download
                </v-icon>
              </template>
              <span>Baixar arquivo</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>

    <v-divider class="my-5" />

    <v-row>
      <v-col class="text-right">
        <BaseButton @click="back" outlined color="primary" title="Voltar" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { showMessage } from '@/helpers/messages';
import { formatDate } from '@/helpers/formatting';
import { downloadFile } from '@/helpers/download';
import { ImplementationService } from '@/services/api/implementation';

export default {
  data: () => ({
    items: [],
    situation: ['Em revisão', 'Revisado'],
    headers: [
      { text: 'Tipo', value: 'productType' },
      { text: 'Data de envio', value: 'createdAt' },
      { text: 'Arquivo', value: 'fileName' },
      { text: 'Status', value: 'situation' },
      { text: '', value: 'action' }
    ]
  }),

  props: {
    implementationId: {
      type: String
    }
  },

  computed: {
    cannotEdit() {
      const { situation } = this.$route.params;

      return situation === 'Concluído' ? true : false;
    }
  },

  created() {
    this.search();
  },

  methods: {
    formatDate,

    back() {
      this.$emit('back');
    },

    async search() {
      try {
        const implementationService = new ImplementationService();
        const { status, data } = await implementationService.getHistoryFiles({
          implementationId: this.implementationId
        });

        if (status === 200) {
          this.items = data;
        } else {
          this.items = [];
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async changeSituation(item) {
      try {
        const implementationService = new ImplementationService();
        const { status } = await implementationService.putHistoryFileSituation({
          implementationId: this.implementationId,
          fileId: item.id,
          situation: item.situation
        });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async downloadDocument(item) {
      try {
        const implementationService = new ImplementationService();
        const { status, data } =
          await implementationService.downloadHistoryOrErrorFile({
            implementationId: this.implementationId,
            fileName: item.fileName
          });

        if (status === 200) {
          const { fileContent, fileName } = data;
          downloadFile(fileContent, fileName);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
